<template>
  <div class="ec-container">
    <div :ref="id" :style="{ width: width, height: height }"></div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'a'
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    colors: {
      type: String,
      default: ''
    },
    Ldata: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      timer: null,
      start: 0,
      end: 0
    }
  },
  watch: {
    Ldata() {
      this.drawLine()
    }
  },

  methods: {
    drawLine() {
    var that = this
      that.myChart.setOption( that.setLOption())
    },
    setLOption() {
      return {
        animation: false,
        backgroundColor: '#ffffff',
        grid: {
          show: false,
          left: '0%',
          right: '0%',
          bottom: '5%',
          top: '5%'
        },
        xAxis: {
          type: 'category',
          show:false, // 不显示坐标轴线、坐标轴刻度线和坐标轴上的文字
        },
        yAxis: {
          type: 'value',
          show:false,
          scale: true
        },
        series: [
          {
            data: this.Ldata,
            type: 'line',
            lineStyle: {
              color: this.colors,
            },
            smooth: true,
            showSymbol: false,
          }
        ]
      }
    }
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
  },
  mounted() {
    this.$nextTick(() => {
      this.myChart = this.$echarts.init(this.$refs[this.id])
      this.drawLine()
    })
  }
}
</script>

<style>
.ec-container {
  padding: 0 2px;
}
</style>
