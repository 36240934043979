<template>
  <div class="container">
    <!-- 头部 -->
    <header>
      <!-- 语言 -->
      <div class="top">
        <div class="title">{{ $t('tabBar').home }}</div>
        <van-popover v-model="showPopover" placement="bottom-end" :actions="actions" @select="onSelect">
          <template #reference>
            <div class="lang_s" @click="showPopover = !showPopover">
              <img src="@/assets/img/lang_select.png" width="15" />
              <span>{{ nowLang }}</span>
              <van-icon name="arrow-down" color="#666666" size="13" />
            </div>
          </template>
        </van-popover>
      </div>
      <!-- 用户搜索 -->
      <div class="u_s">
        <div class="user" @click="showUser = true">
          <img v-if="userInfo.Head" :src="userInfo.Head" width="35" />
          <img v-else src="@/assets/img/heard.png" width="35" />
          <span v-if="userInfo.Id">ID:{{ userInfo.Id }}</span>
        </div>
        <div class="search" @click="$router.push({ name: 'Search' })">{{ $t('public').search }}</div>
      </div>
    </header>
    <!-- 轮播图 -->
    <van-swipe class="swipe-container" :autoplay="3000" indicator-color="#ffffff">
      <van-swipe-item v-for="(item, index) in rotationPics" :key="index">
        <img @click="$router.push(item.JumpLink)" :src="item.Path" alt="" />
      </van-swipe-item>
    </van-swipe>
    <!-- 充币客服 -->
    <div class="t_btn">
      <div class="item" @click="$router.push({ name: 'Recharge' })">
        <img src="@/assets/img/h_with.png" width="69" />
        <span>{{ $t('home').toRecharge }}</span>
      </div>
      <div class="item2" @click="goServe">
        <img src="@/assets/img/h_server.png" width="69" />
        <span>{{ $t('home').lineServer }}</span>
      </div>
    </div>
    <!-- 产品列表 -->
    <div class="pro_list">
      <!-- 实时价格 -->
      <div class="title">{{ $t('home').newPrice }}<span>USDT</span></div>
      <div class="item" v-for="(item, index) in pList" :key="`pro${index}`"
        :style="index == pList.length - 1 ? 'border-bottom: none' : ''"
        @click="$router.push({ name: 'EtfInfo', query: { code: item.Code } })">
        <img :src="item.LogoUrl" />
        <div class="name">{{ item.Name }}</div>
        <div class="chark">
          <EcharI :id="item.Code" width="54px" height="29px" :Ldata="item.Prices"
            :colors="item.IsRise ? '#FF5639' : '#02B270'" />
        </div>
        <div class="flu" :class="item.IsRise ? 'red' : 'green'">{{ item.Fluctuation }}%</div>
        <div class="price">{{ item.LastPrice }}</div>
      </div>
    </div>
    <!-- 新闻列表 -->
    <div class="new_list">
      <div class="title">{{ $t('home').news }}
        <span @click="$router.push({ name: 'News' })">{{ $t('home').seeAll }}<van-icon name="arrow" color="#999999"
            size="15" style="margin-left: 3px;" /></span>
      </div>
      <div class="item" v-for="(item, index) in newsList" :key="`news${index}`"
        :style="index == newsList.length - 1 ? 'border-bottom: none' : ''"
        @click="$router.push({ name: 'Detail', query: { id: item.Id } })">
        <div class="txt">
          <div class="name">{{ item.Ttile }}</div>
          <div class="time">{{ item.ShowCreateTime | getLocalDate }}</div>
        </div>
        <img :src="item.Img" width="65" height="65" />
      </div>
    </div>
    <!-- 个人中心 -->
    <van-popup v-model="showUser" closeable position="left">
      <div class="muser">
        <!-- 用户 -->
        <div class="heard">
          <img v-if="userInfo.Head" :src="userInfo.Head" width="41" />
          <img v-else src="@/assets/img/heard.png" width="41" />
          <div class="name">
            <div class="uid" v-if="userInfo.Id">ID：{{ userInfo.Id }}</div>
            <div class="pid" v-if="userInfo.ParentId">{{ $t('public').parent }}ID：{{ userInfo.ParentId }}</div>
          </div>
        </div>
        <!-- 实名认证 -->
        <div class="item" @click="$router.push({ name: 'Auth' })">
          <div class="name">
            <img src="@/assets/img/m_cert.png" width="15" />
            <span>{{ $t('home').auth }}</span>
          </div>
          <van-icon name="arrow" color="#333333" size="15" />
        </div>
        <!-- 账单 -->
        <div class="item" @click="$router.push({ name: 'Bill' })">
          <div class="name">
            <img src="@/assets/img/m_bill.png" width="15" />
            <span>{{ $t('home').bill }}</span>
          </div>
          <van-icon name="arrow" color="#333333" size="15" />
        </div>
        <!-- 安全中心 -->
        <div class="item" @click="$router.push({ name: 'SafeCenter' })">
          <div class="name">
            <img src="@/assets/img/m_safe.png" width="15" />
            <span>{{ $t('home').safeCenter }}</span>
          </div>
          <van-icon name="arrow" color="#333333" size="15" />
        </div>
        <!-- 消息通知 -->
        <div class="item" @click="$router.push({ name: 'Message' })">
          <div class="name">
            <img src="@/assets/img/m_notify.png" width="15" />
            <span>{{ $t('home').message }}</span>
          </div>
          <van-icon name="arrow" color="#333333" size="15" />
        </div>
        <!-- 提币地址 -->
        <div class="item" @click="$router.push({ name: 'WithAddress' })">
          <div class="name">
            <img src="@/assets/img/m_with.png" width="15" />
            <span>{{ $t('home').withAddress }}</span>
          </div>
          <van-icon name="arrow" color="#333333" size="15" />
        </div>
        <!-- 关于我们 -->
        <div class="item" @click="$router.push({ name: 'About' })">
          <div class="name">
            <img src="@/assets/img/m_about.png" width="15" />
            <span>{{ $t('home').about }}</span>
          </div>
          <van-icon name="arrow" color="#333333" size="15" />
        </div>
        <!-- 白皮书 -->
        <div class="item" @click="getWhitePaper">
          <div class="name">
            <img src="@/assets/img/m_paper.png" width="15" />
            <span>{{ $t('home').whitePaper }}</span>
          </div>
          <van-icon name="arrow" color="#333333" size="15" />
        </div>
        <!-- 设置 -->
        <div class="item" @click="$router.push({ name: 'Setting' })">
          <div class="name">
            <img src="@/assets/img/m_set.png" width="15" />
            <span>{{ $t('home').setting }}</span>
          </div>
          <van-icon name="arrow" color="#333333" size="15" />
        </div>
        <!-- 下载app -->
        <div class="btn" @click="$router.push({ name: 'Share' })">
          <img src="@/assets/img/logo.png" width="30" />
          <span>{{ $t('home').downApp }}</span>
        </div>
      </div>
    </van-popup>
    <!-- 群聊弹窗 -->
    <van-dialog v-model="showAddChat" :showConfirmButton="false" closeOnClickOverlay width="350">
      <div class="addChatInfo">
        <div class="di_info">
          <van-icon @click="showAddChat = false" name="cross" size="15" color="#fff" class="close" />
          <div class="di_i_btn" @click="showAddChat = false">{{ $t('home').addChat }}</div>
        </div>
      </div>
    </van-dialog>
    <TabBar :active="'Home'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import systemApi from '@/api/system'
import productApi from '@/api/product'
import TabBar from '@/components/tabBar'
import { Token } from '@/utils/mm'
import { showName } from '@/utils/validate'
import EcharI from '@/components/echarI'

export default {
  components: {
    EcharI,
    TabBar
  },
  data() {
    return {
      showAddChat: false,
      showUser: false,
      userInfo: {},
      pList: [],
      rotationPics: [],
      newsList: [],
      show: false,
      CommonNotice: {},
      showPopover: false,//选择语言
      nowLang: '',
      actions: [
        { text: '简体中文', lang: 'zh' },//简体中心
        { text: 'English', lang: 'en' },//英语
        // { text: '日本語', lang: 'jp' },//日语
        // { text: '한국인', lang: 'hy' },//韩语
        // { text: 'ภาษาไทย', lang: 'ty' },//泰语
        // { text: 'Indonesia', lang: 'yn' },//印尼语
        // { text: 'Việt nam', lang: 'yuenan' },//越南文
        // { text: 'កម្ពុជា', lang: 'gmw' },//高棉文
        // { text: 'Français', lang: 'fy' },//法语
        // { text: 'Español', lang: 'xby' },//西班牙语
        // { text: 'Русский язык', lang: 'ey' },//俄语
        // { text: 'Português', lang: 'pty' },//葡萄牙语
        // { text: 'Deutsch', lang: 'dy' },//德语
      ]
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'connection', 'baseMsg']),
  },
  mounted() {
    this.nowLang = showName(localStorage.getItem('language') || 'zh')
    if (Token.get())
      this.getAccountBase()
    this.getHomeProduct()
    this.getRotationList()
    this.getNewsList()
    this.signalRStart()
  },
  methods: {
    // 客服
    async goServe() {
      const res = await systemApi.getClientConfig()
      location.href = res.Data.Service
    },
    // 首页ETF产品列表
    async getHomeProduct() {
      const res = await productApi.getHomeProduct()
      this.pList = res.Data
    },
    //推送
    signalRStart() {
      let that = this
      const connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke('SendMsg', 'product')
        }
      }, 100)
      //最新价格
      connection.on('secondPrices', function (res) {
        res.forEach((item) => {
          //产品列表
          that.pList.forEach((sItem) => {
            if (item.Code === sItem.Code && item.Price > 0) {
              sItem.LastPrice = item.Price
              sItem.Fluctuation = item.Fluctuation
            }
          })
        })
      })
    },
    //获取轮播图
    async getRotationList() {
      const rList = await systemApi.getRotation()
      if (rList.Data.length > 0) {
        this.rotationPics = rList.Data
      }
    },
    //获取新闻列表
    async getNewsList() {
      const res = await systemApi.getContentTitles('News', {})
      this.newsList = res.Data
    },
    //获取白皮书
    async getWhitePaper() {
      const res = await systemApi.getContentTitles('WhitePaper', {})
      if (res.Data.length > 0) {
        this.$router.push({ name: 'Detail', query: { id: res.Data[0].Id } })
      }
    },
    // 获取用户信息
    async getAccountBase() {
      const res = await userApi.getAccountBase()
      this.userInfo = res.Data
    },
    //选择语言
    async onSelect(action) {
      var lang = action.lang
      this.$i18n.locale = lang
      this.$store.commit('SET_LANG', lang)
      localStorage.setItem('language', lang)
      this.nowLang = showName(lang)
      this.getNewsList()
      //延时更新用户默认语言
      // setTimeout(() => {
      //   userApi.UpdateLang()
      // }, 300);
    },
  }
}
</script>

<style lang="less" scoped>
.red {
  color: #C92B2E;
}

.green {
  color: #02B270
}

.container {
  min-height: 100vh;
  font-size: 14px;
  padding-bottom: 51px;
  background: #F6F6F6;

  ::v-deep .van-popup--left {
    top: 65px;
    height: calc(100vh - 50px) !important;
    width: 60%;
    transform: translate3d(0, 0, 0);
    border-radius: 0 10px 0 0;
  }

  ::v-deep .van-popup__close-icon {
    color: #000000;
  }

  header {
    width: 100%;
    height: 120px;
    background-image: url(../../assets/img/heard_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 13px;

      .title {
        font-weight: 800;
        font-size: 17px;
        color: #333333;
        width: 55%;
        text-align: right;
      }

      .lang_s {
        display: flex;
        align-items: center;
        height: 30px;

        span {
          margin: 1px 5px 0 5px;
          font-size: 14px;
          color: #666666;
        }
      }
    }

    .u_s {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px;

      .user {
        display: flex;
        align-items: center;

        img {
          border-radius: 50%;
        }

        span {
          font-weight: bold;
          font-size: 14px;
          color: #333333;
          margin-left: 5px;
        }
      }

      .search {
        width: 105px;
        height: 35px;
        background: #FFFFFF;
        border-radius: 18px;
        font-size: 14px;
        color: #999999;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 12px;
      }
    }
  }

  .swipe-container {
    margin: 0 13px;
    border-radius: 5px;

    img {
      height: 196px;
      width: 100vw;
      border-radius: 5px;
    }

    & /deep/ .van-swipe__indicator {
      width: 8px;
      height: 8px;
    }
  }

  .t_btn {
    margin: 15px 13px 10px 13px;
    display: flex;
    justify-content: space-between;

    span {
      font-weight: bold;
      font-size: 15px;
      color: #FFFFFF;
    }

    .item {
      width: 171px;
      height: 47px;
      background: linear-gradient(317deg, #FF8020 0%, #FFA560 100%);
      border-radius: 5px 5px 5px 5px;
      display: flex;
      align-items: center;
    }

    .item2 {
      width: 171px;
      height: 47px;
      background: linear-gradient(308deg, #2E2E2E 0%, #414141 100%);
      border-radius: 5px 5px 5px 5px;
      display: flex;
      align-items: center;
    }

  }

  .pro_list {
    margin: 0 13px;
    padding: 15px 13px 0 13px;
    background: #fff;
    border-radius: 5px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: 17px;
      color: #333333;

      span {
        font-weight: 500;
        font-size: 14px;
        color: #999999;
      }
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #E3E3E3;

      img {
        width: 27px;
        height: 27px;
        border-radius: 50%;
      }

      .name {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        padding: 0 10px 0 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 35%;
      }

      .chark {
        border-radius: 1px;
        border: 1px solid #E3E3E3;
      }

      .flu {
        font-size: 15px;
        padding: 0 30px 0 10px;
      }

      .price {
        text-align: right;
        width: 30%;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }
    }
  }

  .new_list {
    margin: 10px 13px 30px 13px;
    padding: 15px 13px 0 13px;
    background: #fff;
    border-radius: 5px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: 17px;
      color: #333333;
      margin-bottom: 20px;

      span {
        font-weight: 500;
        font-size: 14px;
        color: #999999;
      }
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      border-bottom: 1px solid #E3E3E3;

      .txt {
        width: 250px;

        .name {
          font-weight: bold;
          font-size: 15px;
          color: #333333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 21px;
        }

        .time {
          margin-top: 5px;
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }

  .muser {
    margin-top: 50px;

    .heard {
      display: flex;
      justify-content: left;
      align-items: center;
      margin: 0 15px;

      img {
        border-radius: 50%;
      }

      .name {
        margin-left: 5px;

        .uid {
          font-weight: bold;
          font-size: 15px;
          color: #333333;
          margin: 3px 0 7px 0;
        }

        .pid {
          font-size: 12px;
          color: #999999;
        }
      }
    }

    .item {
      margin: 35px 13px 0 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        display: flex;
        align-items: center;

        span {
          margin-left: 10px;
          font-size: 15px;
          color: #333333;
        }
      }
    }

    .btn {
      margin: 40px 13px 0 13px;
      padding: 5px 0;
      border-radius: 4px;
      border: 1px solid #DCDCDC;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        margin-left: 5px;
        font-size: 15px;
        color: #C92B2E;
      }
    }
  }
}

::v-deep .van-dialog {
  background-color: transparent;
}

.addChatInfo {
  background-image: url(../../assets/img/h_addChat.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .di_info {
    width: 100%;
    height: 464px;
    text-align: center;
    padding-top: 15px;

    .close {
      margin-left: 300px;
    }

    .di_i_btn {
      margin: 350px 90px 0 90px;
      width: 170px;
      height: 40px;
      background: linear-gradient(180deg, #5D7DC9 0%, #3053A6 100%);
      border-radius: 20px;
      font-weight: bold;
      font-size: 17px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
